import vector1 from "./assets/vector1.svg";
import vector2 from "./assets/vector2.svg";
import { IoChevronDownCircle } from "react-icons/io5";
import Navbar from "./components/Navbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import { useState } from "react";
import Delete from "./pages/Delete";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <Router>
      <div className="flex min-h-screen w-full flex-col items-center relative px-[16px] overflow-hidden">
        <img
          className="absolute left-0 bottom-0 max  max-w-[615px] "
          src={vector1}
          alt=""
        />
        <img className="absolute  right-0  h-full" src={vector2} alt="" />
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/delete" element={<Delete />} />
        </Routes>
      </div>
      <Del />
      <Toaster position="top-center" reverseOrder={false} />
    </Router>
  );
}

const Del = () => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  return (
    <div className="flex fixed right-[20px] z-[9999999] bottom-[50px]">
      <div className=" relative"></div>
      <span
        onClick={() => setToggle(!toggle)}
        className=" bg-[orange] relative rounded-full p-1 z-[999999] cursor-pointer text-white "
      >
        <IoChevronDownCircle className="text-[30px]" />
      </span>
      {toggle && (
        <span
          onClick={() => {
            setToggle(!toggle);
            navigate("/delete");
          }}
          className=" text-red-600 cursor-pointer absolute top-[-30px] whitespace-nowrap right-0 border border-gray-300 shadow-md bg-[#e7e7e7] px-[12px] text-[14px] rounded-[20px]"
        >
          Delete My account
        </span>
      )}
    </div>
  );
};

export default App;
