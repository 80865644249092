import React from "react";

const Privacy = () => {
  return (
    <div className="flex w-full flex-col gap-[40px] items-center justify-center">
      <div className="max-w-[1200px] w-full flex flex-col gap-[40px] py-[40px]">
        <div className="flex flex-col items-center">
          <span className="text-[36px] font-[600] text-center">
            Dolla Privacy Policy
          </span>
          <span className="text-slate-600 text-center">
            Effective Date: August 27, 2024
          </span>
        </div>
        <div className="  w-full flex flex-col gap-[16px]">
          <span className="text-[20px] font-[600]">Introduction</span> Dolla is
          committed to protecting your privacy and ensuring the security of your
          personal information. This Privacy Policy outlines the types of
          information we collect, how we use it, and the measures we take to
          safeguard your data.
        </div>
        <div className="  w-full flex flex-col gap-[16px]">
          <span className="text-[20px] font-[600]">Information We Collect</span>{" "}
          We may collect the following types of information from you:
          <ul className="flex flex-col gap-[12px]">
            <li>
              <span className="font-[600]">-Personal Information:</span> This
              includes your name and email address.
            </li>
            <li>
              <span className="font-[600]">-Usage Data:</span> This includes
              information about how you use our platform, such as your IP
              address, browser type, and pages visited.
            </li>
          </ul>
        </div>
        <div className="  w-full flex flex-col gap-[16px]">
          <span className="text-[20px] font-[600]">
            How We Use Your Information
          </span>{" "}
          We may use your information to:
          <ul className="flex flex-col gap-[12px]">
            <li>-Process your transactions and provide our services.</li>
            <li>-Verify your identity.</li>
            <li>-Prevent fraud and protect our platform.</li>
            <li>-Improve our products and services.</li>
            <li>-Communicate with you about our products and services.</li>
            <li>-Comply with legal requirements.</li>
          </ul>
        </div>
        <div className="  w-full flex flex-col gap-[16px]">
          <span className="text-[20px] font-[600]">
            Sharing Your Information
          </span>{" "}
          We may share your information with:
          <ul className="flex flex-col gap-[12px]">
            <li>-Our service providers who help us operate our platform.</li>
            <li>
              -Law enforcement agencies or regulatory bodies as required by law.
            </li>
          </ul>
        </div>
        <div className="  w-full flex flex-col gap-[16px]">
          <span className="text-[20px] font-[600]">Your Rights</span> You have
          the right to:
          <ul className="flex flex-col gap-[12px]">
            <li>-Access and correct your personal information.</li>
            <li>-Request the deletion of your personal information.</li>
            <li>-Object to the processing of your personal information.</li>
            <li>
              -Withdraw your consent to the processing of your personal
              information.{" "}
            </li>
          </ul>
        </div>

        <div className="  w-full flex flex-col gap-[16px]">
          <span className="text-[20px] font-[600]">Security</span> We implement
          reasonable security measures to protect your personal information from
          unauthorized access, disclosure, alteration, or destruction. However,
          no security system is completely foolproof, and we cannot guarantee
          the absolute security of your information.
        </div>
        <div className="  w-full flex flex-col gap-[16px]">
          <span className="text-[20px] font-[600]">
            Changes to This Privacy Policy
          </span>{" "}
          We may update this Privacy Policy from time to time. We will notify
          you of any significant changes by posting a notice on our website or
          by contacting you directly.
        </div>
        <div className="  w-full flex flex-col gap-[16px]">
          <span className="text-[20px] font-[600]">Contact Us</span> If you have
          any questions about this Privacy Policy, please contact us at
          hello@dolla.cards
        </div>
        <span className="text-[16px] w-full font-[700]">
          By using our platform, you consent to the collection and use of your
          information as described in this Privacy Policy.
        </span>
      </div>
    </div>
  );
};

export default Privacy;
