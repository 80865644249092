import React from "react";
import store1 from "../assets/store1.svg";
import store2 from "../assets/store2.svg";
import phone from "../assets/phone.svg";
// import { useNavigate } from "react-router-dom";

const Home = () => {
  return (
    <div className="w-full max-w-[1260px] z-[99999] min-h-[calc(100vh-80px)] flex md:flex-row flex-col justify-between overflow-hidden">
      <div className="flex flex-col h-full md:min-h-[calc(100vh-80px)] w-full  justify-center md:items-start items-center md:py-4 py-[50px] ">
        <span className="text-[48px] font-[700] text-[#000] max-w-[469px] md:text-start text-center">
          Seamless Payments at Your Fingertips
        </span>
        <span className="text-[18px] font-[500] mt-[23px] text-[#686981] max-w-[428px] md:text-start text-center">
          One Tap Away – Start Exploring Endless Possibilities Today!
        </span>
        <div className="flex gap-[33px] mt-[93px] items-center">
          <span
            onClick={() =>
              window.open("https://testflight.apple.com/join/1eqVfagj")
            }
            className="relative w-fit h-fit cursor-pointer"
          >
            <span className="text-[8px] absolute top-[-5px] right-0 bg-[#ebad31] px-[6px] rounded-full text-white">
              Get Beta Access
            </span>
            <img src={store1} alt="" />
          </span>
          <span
            onClick={() => window.open("https://forms.gle/VUoc3mVjiG5ccPV17")}
            className="relative w-fit h-fit cursor-pointer"
          >
            <span className="text-[8px] absolute top-[-5px] right-0 bg-[grey] px-[6px] rounded-full text-white">
              Get Access
            </span>
            <img src={store2} alt="" />
          </span>
        </div>
      </div>
      <div className="flex w-full h-full lg:min-h-[calc(100vh-80px)] lg:items-end items-center md:justify-end justify-center">
        <img
          className=" h-[70%]  w-full md:min-h-[700px] "
          src={phone}
          alt=""
        />
      </div>
    </div>
  );
};

export default Home;
